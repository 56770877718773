import { createBrowserHistory } from 'history';

import { userNavigationMenuRoutePath } from '@itm/shared-frontend/lib/utils';

export const history = createBrowserHistory();

export const RoutePath = {
  ...userNavigationMenuRoutePath,

  root: '/',
  permissionDenied: '/permission-denied',
  // Auth
  authRoot: '/auth',
  login: '/auth/login',
  logout: '/auth/logout',
  signUp: '/auth/signup/:verificationToken',
  setupMFA: '/auth/mfa/setup',
  resetMFA: '/auth/mfa/reset',
  changePasswordCompleted: '/auth/password/change/completed',
  resetPassword: '/auth/password/reset',
  resetPasswordCompleted: '/auth/password/reset/completed',
  termsOfUse: '/auth/agreements/terms-of-use',
  agreementsDataProtection: '/auth/agreements/data-protection',

  // Dashboard
  dashboard: '/dashboard',
  dashboardRequestDemoSuccess: '/dashboard/request-demo-success',

  //Data
  configurationRoot: '/configuration',
  configurationEmailConfigurationRoot: '/configuration/emailConfiguration',
  configurationEmailConfigurationTemplateList: '/configuration/emailConfiguration/list',
  configurationEmailConfigurationTemplateCreate: '/configuration/emailConfiguration/create',

  //Data
  dataRoot: '/data',
  //Data Access
  dataAccessRoot: '/data/access',
  dataAccessMyAccessRoot: '/data/access/my-access',
  dataAccessMyAccessList: '/data/access/my-access/list',
  dataAccessMyAccessCreate: '/data/access/my-access/create',
  dataAccessMyAccessViewDetails: '/data/access/my-access/view/:dataAccessId/details',
  dataAccessManageRoot: '/data/access/manage',
  dataAccessManagePendingRequests: '/data/access/manage/request-access',
  dataAccessManagePendingRequestsViewDetails: '/data/access/manage/request-access/view/:dataAccessRequestId/details',
  dataAccessManageDataAccessDetails: '/data/access/manage/data-access-details',
  dataAccessManageDataAccessDetailsViewDetails:
    '/data/access/manage/data-access-details/view/:dataAccessRequestId/details',
  // Data Retention
  dataRetentionRoot: '/data/retention',
  dataRetentionPolicyRoot: '/data/retention/company/:companyId/policy',
  dataRetentionPolicyList: '/data/retention/company/:companyId/policy/list',
  dataRetentionPolicyCreateRoot: '/data/retention/company/:companyId/policy/create',
  dataRetentionPolicyCreateDetails: '/data/retention/company/:companyId/policy/create/details',
  dataRetentionPolicyCreateRules: '/data/retention/company/:companyId/policy/create',
  dataRetentionPolicyViewRoot: '/data/retention/company/:companyId/policy/view/:policyId/version/:policyVersionId',
  dataRetentionPolicyViewDetails:
    '/data/retention/company/:companyId/policy/view/:policyId/version/:policyVersionId/details',
  dataRetentionPolicyViewRules:
    '/data/retention/company/:companyId/policy/view/:policyId/version/:policyVersionId/rules',
  dataRetentionPolicyViewVersions:
    '/data/retention/company/:companyId/policy/view/:policyId/version/:policyVersionId/versions',
  dataRetentionPolicyViewVersionsDetails:
    '/data/retention/company/:companyId/policy/view/:policyId/version/:policyVersionId/versions/:policyVersionHistoryId/details',
  dataRetentionPolicyEditRoot: '/data/retention/company/:companyId/policy/edit/:policyId/version/:policyVersionId',
  dataRetentionPolicyEditDetails:
    '/data/retention/company/:companyId/policy/edit/:policyId/version/:policyVersionId/details',
  dataRetentionPolicyEditRules:
    '/data/retention/company/:companyId/policy/edit/:policyId/version/:policyVersionId/rules',
  dataRetentionPolicyEditVersions:
    '/data/retention/company/:companyId/policy/edit/:policyId/version/:policyVersionId/versions',
  dataRetentionPolicyEditVersionsDetails:
    '/data/retention/company/:companyId/policy/edit/:policyId/version/:policyVersionId/versions/:policyVersionHistoryId/details',
  dataRetentionLogs: '/data/retention/company/:companyId/logs',
  // Data Publish Configuration
  dataPublishRoot: '/data/publish',
  dataPublishConfiguration: '/data/publish/configuration',

  // Administration
  administrationRoot: '/administration',
  administrationConsole: '/administration/console',
  administrationProducts: '/administration/console/products',
  administrationGeneral: '/administration/console/general',
  administrationAudit: '/administration/console/audit',

  // Users
  administrationUserRoot: '/administration/user',
  administrationUserList: '/administration/user/list',
  // Users Create
  administrationUserCreateRoot: '/administration/user/create',
  administrationUserCreatePersonalInformation: '/administration/user/create/new/personal-information',
  administrationUserCreatePermissions: '/administration/user/create/new/permissions',
  administrationUserCreateClientAccess: '/administration/user/create/new/client-access',
  // Users Update
  administrationUserUpdateRoot: '/administration/user/update/:userId',
  administrationUserUpdateUserDetail: '/administration/user/update/:userId/user-detail',
  administrationUserUpdateAuthHistory: '/administration/user/update/:userId/auth-history',
  administrationUserUpdateSFTP: '/administration/user/update/:userId/sftp',
  administrationUserUpdateRolesAndPermissions: '/administration/user/update/:userId/roles-and-permissions',
  administrationUserUpdateRolesAndPermissionsCompanyList:
    '/administration/user/update/:userId/roles-and-permissions/company/list',
  administrationUserUpdateRolesAndPermissionsCompanyProductRoot:
    '/administration/user/update/:userId/roles-and-permissions/company/:companyId/product',
  administrationUserUpdateRolesAndPermissionsCompanyProductList:
    '/administration/user/update/:userId/roles-and-permissions/company/:companyId/product/list',
  administrationUserUpdateRolesAndPermissionsCompanyProductSchemeList:
    '/administration/user/update/:userId/roles-and-permissions/company/:companyId/product/:productId/scheme/list',
  administrationUserUpdateRolesAndPermissionsCompanyProductSchemeEmployerList:
    '/administration/user/update/:userId/roles-and-permissions/company/:companyId/product/:productId/scheme/:schemeId/employer/list',
  // Onboarding
  administrationOnboardingRoot: '/administration/onboarding',
  // Onboarding Company
  administrationOnboardingCompanyRoot: '/administration/onboarding/company',
  administrationOnboardingCompanyList: '/administration/onboarding/company/list',
  // Onboarding Company Create
  administrationOnboardingCompanyCreateRoot: '/administration/onboarding/company/create',
  administrationOnboardingCompanyCreateDetails: '/administration/onboarding/company/create/:companyId/details',
  administrationOnboardingCompanyCreateSchemes: '/administration/onboarding/company/create/:companyId/schemes',
  administrationOnboardingCompanyCreateProducts: '/administration/onboarding/company/create/:companyId/products',
  // Onboarding Company Update
  administrationOnboardingCompanyUpdateRoot: '/administration/onboarding/company/update/:companyId',
  administrationOnboardingCompanyUpdateDetails: '/administration/onboarding/company/update/:companyId/details',
  administrationOnboardingCompanyUpdateCreateScheme:
    '/administration/onboarding/company/update/:companyId/create-scheme',
  administrationOnboardingCompanyUpdateSchemeRoot: '/administration/onboarding/company/update/:companyId/scheme',
  administrationOnboardingCompanyUpdateSchemeList: '/administration/onboarding/company/update/:companyId/scheme/list',
  administrationOnboardingCompanyUpdateSchemeUpdateSchemeRoot:
    '/administration/onboarding/company/update/:companyId/scheme/update/:schemeId',
  administrationOnboardingCompanyUpdateSchemeUpdateSchemeDetails:
    '/administration/onboarding/company/update/:companyId/scheme/update/:schemeId/details',
  administrationOnboardingCompanyUpdateSchemeUpdateSections:
    '/administration/onboarding/company/update/:companyId/scheme/update/:schemeId/sections',
  administrationOnboardingCompanyUpdateSchemeUpdateSchemeSectionsUpdate:
    '/administration/onboarding/company/update/:companyId/scheme/update/:schemeId/sections/update/:sectionId',
  administrationOnboardingCompanyUpdateSchemeUpdateSchemeSectionsCreateSection:
    '/administration/onboarding/company/update/:companyId/scheme/update/:schemeId/sections/create',
  administrationOnboardingCompanyUpdateSchemeUpdateSchemeEmployers:
    '/administration/onboarding/company/update/:companyId/scheme/update/:schemeId/employers',
  administrationOnboardingCompanyUpdateSchemeUpdateSchemeEmployersUpdateEmployer:
    '/administration/onboarding/company/update/:companyId/scheme/update/:schemeId/employers/:employerId/details',
  administrationOnboardingCompanyUpdateProducts: '/administration/onboarding/company/update/:companyId/products',
  administrationOnboardingCompanyUpdateSchemeAccessRoot:
    '/administration/onboarding/company/update/:companyId/default-scheme-access',
  administrationOnboardingCompanyUpdateSchemeAccessMain:
    '/administration/onboarding/company/update/:companyId/default-scheme-access/main',
  administrationOnboardingCompanyUpdateSchemeAccessAudit:
    '/administration/onboarding/company/update/:companyId/default-scheme-access/audit',
  // Onboarding Company View
  administrationOnboardingCompanyViewRoot: '/administration/onboarding/company/view/:companyId',
  administrationOnboardingCompanyViewDetails: '/administration/onboarding/company/view/:companyId/details',
  administrationOnboardingCompanyViewCreateScheme: '/administration/onboarding/company/view/:companyId/create-scheme',
  administrationOnboardingCompanyViewSchemeList: '/administration/onboarding/company/view/:companyId/scheme/list',
  administrationOnboardingCompanyViewSchemeRoot: '/administration/onboarding/company/view/:companyId/scheme',
  administrationOnboardingCompanyViewSchemeViewSchemeDetails:
    '/administration/onboarding/company/view/:companyId/scheme/view/:schemeId/details',
  administrationOnboardingCompanyViewSchemeViewSections:
    '/administration/onboarding/company/view/:companyId/scheme/view/:schemeId/sections',
  administrationOnboardingCompanyViewSchemeViewSchemeSectionsView:
    '/administration/onboarding/company/view/:companyId/scheme/view/:schemeId/sections/view/:sectionId',
  administrationOnboardingCompanyViewSchemeViewSchemeSectionsCreateSection:
    '/administration/onboarding/company/view/:companyId/scheme/view/:schemeId/sections/create',
  administrationOnboardingCompanyViewSchemeViewSchemeEmployers:
    '/administration/onboarding/company/view/:companyId/scheme/view/:schemeId/employers',
  administrationOnboardingCompanyViewSchemeViewSchemeEmployersViewEmployer:
    '/administration/onboarding/company/view/:companyId/scheme/view/:schemeId/employers/:employerId/details',
  administrationOnboardingCompanyViewProducts: '/administration/onboarding/company/view/:companyId/products',
  administrationOnboardingCompanyViewSchemeAccessRoot:
    '/administration/onboarding/company/view/:companyId/default-scheme-access',
  administrationOnboardingCompanyViewSchemeAccessMain:
    '/administration/onboarding/company/view/:companyId/default-scheme-access/main',
  administrationOnboardingCompanyViewSchemeAccessAudit:
    '/administration/onboarding/company/view/:companyId/default-scheme-access/audit',

  // Onboarding Bulk Uploads
  administrationOnboardingOnboardRoot: '/administration/onboarding/onboard',
  administrationOnboardingBulkUpload: '/administration/onboarding/onboard/company/:companyId/upload',
  administrationOnboardingBulkUploadHistory: '/administration/onboarding/onboard/company/:companyId/history',
  administrationOnboardingBulkUploadHistoryFile:
    '/administration/onboarding/onboard/company/:companyId/history/file/:fileId',
  // Onboarding Tenant
  administrationOnboardingTenantRoot: '/administration/onboarding/tenant',
  administrationOnboardingTenantList: '/administration/onboarding/tenant/list',
  administrationOnboardingTenantCreateRoot: '/administration/onboarding/tenant/create',
  administrationOnboardingTenantCreateDetails: '/administration/onboarding/tenant/create/:tenantId/details',
  administrationOnboardingTenantCreateBranding: '/administration/onboarding/tenant/create/:tenantId/branding',
  administrationOnboardingTenantCreateProducts: '/administration/onboarding/tenant/create/:tenantId/products',
  administrationOnboardingTenantUpdateRoot: '/administration/onboarding/tenant/update/:tenantId',
  administrationOnboardingTenantUpdateDetails: '/administration/onboarding/tenant/update/:tenantId/details',
  administrationOnboardingTenantUpdateBranding: '/administration/onboarding/tenant/update/:tenantId/branding',
  administrationOnboardingTenantUpdateProducts: '/administration/onboarding/tenant/update/:tenantId/products',
  // Products
  productUpdateRoot: '/administration/product/update/:productId',
  productUpdateCompany: '/administration/product/update/:productId/company',
  companyProductUpdateRoot: '/administration/company/:companyId/product/update/:productId',
  companyProductUpdateScheme: '/administration/company/:companyId/product/update/:productId/scheme',
  // User
  userUpdateAuthHistory: '/administration/user/update/:userId/auth-history',

  // API Integrations
  apiIntegrationsRoot: '/administration/api-integrations',
  apiIntegrationsList: '/administration/api-integrations/list',
  apiIntegrationsCreateRoot: '/administration/api-integrations/create',
  apiIntegrationsCreateDetails: '/administration/api-integrations/create/:apiConnectionId/details',
  apiIntegrationsCreateCredentials: '/administration/api-integrations/create/:apiConnectionId/credentials',
  apiIntegrationsCreateProducts: '/administration/api-integrations/create/:apiConnectionId/products',

  // API Integrations Update and View
  apiIntegrationsUpdateRoot: '/administration/api-integrations/update/:apiConnectionId',
  apiIntegrationsUpdateDetails: '/administration/api-integrations/update/:apiConnectionId/details',
  apiIntegrationsUpdateCredentials: '/administration/api-integrations/update/:apiConnectionId/credentials',
  apiIntegrationsUpdateCredentialsIpAddress:
    '/administration/api-integrations/update/:apiConnectionId/credentials/ip-address',
  apiIntegrationsUpdateProducts: '/administration/api-integrations/update/:apiConnectionId/products',

  apiIntegrationsViewRoot: '/administration/api-integrations/view/:apiConnectionId/',
  apiIntegrationsViewDetails: '/administration/api-integrations/view/:apiConnectionId/details',
  apiIntegrationsViewCredentials: '/administration/api-integrations/view/:apiConnectionId/credentials',
  apiIntegrationsViewProducts: '/administration/api-integrations/view/:apiConnectionId/products',

  // API Integrations Third Party Integrations
  apiIntegrationsThirdPartyRoot: '/administration/api-integrations/third-party',
  apiIntegrationsThirdPartyList: '/administration/api-integrations/third-party/list',

  apiIntegrationsThirdPartyCreateRoot: '/administration/api-integrations/third-party/create',
  apiIntegrationsThirdPartyCreateIntegration: '/administration/api-integrations/third-party/create/integration',
  apiIntegrationsThirdPartyCreateEnvironment: '/administration/api-integrations/third-party/create/environment',

  apiIntegrationsThirdPartyViewRoot: '/administration/api-integrations/third-party/view/:integrationId',
  apiIntegrationsThirdPartyViewIntegration:
    '/administration/api-integrations/third-party/view/:integrationId/integration',
  apiIntegrationsThirdPartyViewEnvironment:
    '/administration/api-integrations/third-party/view/:integrationId/environment',

  apiIntegrationsThirdPartyEditRoot: '/administration/api-integrations/third-party/edit/:integrationId',
  apiIntegrationsThirdPartyEditIntegration:
    '/administration/api-integrations/third-party/edit/:integrationId/integration',
  apiIntegrationsThirdPartyEditEnvironment:
    '/administration/api-integrations/third-party/edit/:integrationId/environment',
};

export { GuardedRoute } from './GuardedRoute';
