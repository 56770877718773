import { useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { AuthContext, logout } from 'src/context/Auth';

import { Converter } from '@itm/shared-frontend/lib/utils';
import { UserNavbarMenu } from '@itm/shared-frontend/lib/components/layout';

function UserNavbarItem() {
  const navigate = useNavigate();
  const { clientPortalUser, clientPortalUserRole } = useContext(AuthContext);

  const userName = useMemo(
    () => (clientPortalUser ? `${clientPortalUser.firstName} ${clientPortalUser.lastName}` : ''),
    [clientPortalUser],
  );

  const userRoleWithoutModuleName = useMemo(
    () =>
      clientPortalUserRole
        ? Converter.stripUpperToCapitalizedAll(clientPortalUserRole.slice(clientPortalUserRole.indexOf('_') + 1))
        : '',
    [clientPortalUserRole],
  );

  const logOutClickHandler = async () => {
    await logout();
    navigate(RoutePath.login);
  };

  return (
    <UserNavbarMenu
      userName={userName}
      authRoleReadableName={userRoleWithoutModuleName}
      logout={logOutClickHandler}
      isClientPortal
    />
  );
}

export default UserNavbarItem;
