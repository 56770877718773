import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

import LumeraLogoSVG from '@itm/shared-frontend/lib/assets/images/lumera-logo.svg?url';

export const tenantSliceSelector = (state: RootState) => state.tenant;

export const tenantBrandingSelector = createSelector(tenantSliceSelector, (state) => state.branding);

export const tenantBrandingNameSelector = createSelector(
  tenantBrandingSelector,
  (state) => state?.name || 'Lumera DataHub',
);
export const tenantBrandingLogoAltSelector = createSelector(tenantBrandingNameSelector, (state) => `${state} logo`);
export const tenantBrandingLogoUrlSelector = createSelector(
  tenantBrandingSelector,
  (state) => state?.logoUrl || LumeraLogoSVG,
);

export const tenantBrandingTermsAndConditionsSelector = createSelector(
  tenantBrandingSelector,
  (state) => state?.termsAndConditions || '',
);

export const tenantIdSelector = createSelector(tenantBrandingSelector, (branding) =>
  branding ? branding.tenantId : null,
);
