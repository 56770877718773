import { useEffect, useMemo, useState } from 'react';
import { useLocation, matchPath, PathMatch, generatePath } from 'react-router-dom';
import { RoutePath as rp } from 'src/router';

import { useSelector, useDispatch } from 'src/store';
import { setBreadcrumbsNames } from 'src/store/slices/breadcrumbsSlice';
import { breadcrumbsNamesMapSelector } from 'src/store/selectors/breadcrumbsSelector';

import { Breadcrumb, Breadcrumbs } from '@itm/shared-frontend/lib/components';

type BreadcrumbConfig = {
  name: string | null | undefined;
  parentPath: string | null;
};

type ExtendedBreadcrumbsConfig = BreadcrumbConfig & Pick<PathMatch<string>, 'pathname'>;

type BreadcrumbConfigMap = {
  [key: string]: BreadcrumbConfig;
};

type UseSetBreadcrumbsNamesProps = {
  routePath: string;
  value: string | null | undefined;
  isClearOnUnmount?: boolean;
};

export const useSetBreadcrumbsNames = ({ routePath, value, isClearOnUnmount = true }: UseSetBreadcrumbsNamesProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!value) return;
    dispatch(setBreadcrumbsNames({ [routePath]: value }));
  }, [routePath, value, dispatch]);

  useEffect(
    () => () => {
      if (!isClearOnUnmount) return;
      dispatch(setBreadcrumbsNames({ [routePath]: null }));
    },
    [routePath, isClearOnUnmount, dispatch],
  );
};

const getBreadcrumbs = (pathToMatch: string, breadcrumbsConfig: BreadcrumbConfigMap): Breadcrumb[] => {
  const matchedList = Object.keys(breadcrumbsConfig).reduce<PathMatch<string>[]>((acc, route) => {
    const matched = matchPath(route, pathToMatch);
    return matched ? [...acc, matched] : acc;
  }, []);

  if (!matchedList.length) return [];

  const pathMatch =
    // try to find the full match
    matchedList.find((m) => !m.pattern.path.endsWith('/*')) ||
    // if not found, find the longest wildcard match
    matchedList.toSorted((a, b) => b.pattern.path.length - a.pattern.path.length)[0];

  const routeParams = pathMatch.params;

  let revertedBreadcrumbsList: Breadcrumb[] = [];
  let extendedConfig: ExtendedBreadcrumbsConfig | null = {
    pathname: pathMatch.pathname,
    ...breadcrumbsConfig[pathMatch.pattern.path],
  };

  while (extendedConfig) {
    const { name, pathname, parentPath }: ExtendedBreadcrumbsConfig = extendedConfig;

    if (name) {
      revertedBreadcrumbsList.push({ name, to: pathname });
    } else {
      revertedBreadcrumbsList = [];
    }

    extendedConfig = parentPath
      ? { ...breadcrumbsConfig[parentPath], pathname: generatePath(parentPath, routeParams) }
      : null;
  }
  return revertedBreadcrumbsList.reverse();
};

function BreadcrumbsTrail() {
  const { pathname } = useLocation();
  const bcNamesMap = useSelector(breadcrumbsNamesMapSelector);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  const isHidden = useMemo(
    () =>
      Boolean(
        matchPath(`${rp.authRoot}/*`, pathname) ||
          matchPath(`${rp.dashboard}/*`, pathname) ||
          matchPath(rp.permissionDenied, pathname),
      ),
    [pathname],
  );

  const config: BreadcrumbConfigMap = useMemo(
    () => ({
      // CONFIGURATION

      [rp.configurationRoot]: { name: 'Configuration', parentPath: null },
      [rp.configurationEmailConfigurationRoot]: {
        name: 'Email Template Configuration',
        parentPath: rp.configurationRoot,
      },
      [rp.configurationEmailConfigurationTemplateList]: {
        name: 'All Templates',
        parentPath: rp.configurationEmailConfigurationRoot,
      },
      [rp.configurationEmailConfigurationTemplateCreate]: {
        name: 'Create New Template',
        parentPath: rp.configurationEmailConfigurationRoot,
      },

      // DATA

      [rp.dataRoot]: { name: 'Data', parentPath: null },

      // Data / Data Access
      [rp.dataAccessRoot]: { name: 'Data Access Permission', parentPath: rp.dataRoot },

      // Data / Data Access / My Access
      [rp.dataAccessMyAccessList]: { name: 'My Access', parentPath: rp.dataAccessRoot },
      [rp.dataAccessMyAccessCreate]: { name: 'Request New Access', parentPath: rp.dataAccessMyAccessList },
      [rp.dataAccessMyAccessViewDetails]: {
        name: bcNamesMap[rp.dataAccessMyAccessViewDetails],
        parentPath: rp.dataAccessMyAccessList,
      },

      // Data / Data Access / Manage Access
      [rp.dataAccessManageRoot]: { name: 'Manage Access', parentPath: rp.dataRoot },
      [rp.dataAccessManagePendingRequests]: { name: 'Pending Requests', parentPath: rp.dataAccessManageRoot },
      [rp.dataAccessManagePendingRequestsViewDetails]: {
        name: bcNamesMap[rp.dataAccessManagePendingRequestsViewDetails],
        parentPath: rp.dataAccessManagePendingRequests,
      },
      [rp.dataAccessManageDataAccessDetails]: { name: 'Data Access Details', parentPath: rp.dataAccessManageRoot },
      [rp.dataAccessManageDataAccessDetailsViewDetails]: {
        name: bcNamesMap[rp.dataAccessManageDataAccessDetailsViewDetails],
        parentPath: rp.dataAccessManageDataAccessDetails,
      },

      // Data / Data Retention
      [rp.dataRetentionRoot]: { name: 'Data Retention', parentPath: rp.dataRoot },
      [`${rp.dataRetentionPolicyCreateRoot}/*`]: { name: 'Create new policy', parentPath: rp.dataRetentionRoot },
      [rp.dataRetentionPolicyList]: { name: 'All Policies', parentPath: rp.dataRetentionRoot },

      // Data / Data Retention / View Policy Details
      [rp.dataRetentionPolicyViewRoot]: {
        name: bcNamesMap[rp.dataRetentionPolicyViewRoot],
        parentPath: rp.dataRetentionPolicyList,
      },
      [rp.dataRetentionPolicyViewDetails]: { name: 'Policy Details', parentPath: rp.dataRetentionPolicyViewRoot },
      [rp.dataRetentionPolicyViewRules]: { name: 'Data Retention Rules', parentPath: rp.dataRetentionPolicyViewRoot },
      [`${rp.dataRetentionPolicyViewVersions}/*`]: {
        name: 'Version History',
        parentPath: rp.dataRetentionPolicyViewRoot,
      },

      // Data / Data Retention / Edit Policy
      [rp.dataRetentionPolicyEditRoot]: {
        name: bcNamesMap[rp.dataRetentionPolicyEditRoot],
        parentPath: rp.dataRetentionPolicyList,
      },
      [rp.dataRetentionPolicyEditDetails]: { name: 'Policy Details', parentPath: rp.dataRetentionPolicyEditRoot },
      [rp.dataRetentionPolicyEditRules]: { name: 'Data Retention Rules', parentPath: rp.dataRetentionPolicyEditRoot },
      [`${rp.dataRetentionPolicyEditVersions}/*`]: {
        name: 'Version History',
        parentPath: rp.dataRetentionPolicyEditRoot,
      },
      [rp.dataRetentionLogs]: { name: 'Data Retention Logs', parentPath: rp.dataRetentionRoot },

      // Data / Data Publish
      [rp.dataPublishRoot]: { name: 'Data Publish Configuration', parentPath: rp.dataRoot },
      [rp.dataPublishConfiguration]: { name: 'Configuration', parentPath: rp.dataPublishRoot },

      // ADMINISTRATION

      [rp.administrationRoot]: { name: 'Admin', parentPath: null },

      // Administration / Users
      [rp.administrationUserRoot]: { name: 'Users', parentPath: rp.administrationRoot },
      [`${rp.administrationUserCreateRoot}/*`]: { name: 'Invite New User', parentPath: rp.administrationUserRoot },
      [rp.administrationUserList]: { name: 'Manage Users', parentPath: rp.administrationUserRoot },
      [rp.administrationUserUpdateRoot]: {
        name: bcNamesMap[rp.administrationUserUpdateRoot],
        parentPath: rp.administrationUserList,
      },
      [rp.administrationUserUpdateUserDetail]: { name: 'User Detail', parentPath: rp.administrationUserUpdateRoot },
      [rp.administrationUserUpdateAuthHistory]: {
        name: 'Authentication History',
        parentPath: rp.administrationUserUpdateRoot,
      },
      [rp.administrationUserUpdateSFTP]: { name: 'SFTP', parentPath: rp.administrationUserUpdateRoot },
      [rp.administrationUserUpdateRolesAndPermissionsCompanyList]: {
        name: 'Roles and Permissions',
        parentPath: rp.administrationUserUpdateRoot,
      },
      [`${rp.administrationUserUpdateRolesAndPermissionsCompanyProductRoot}/*`]: {
        name: bcNamesMap[rp.administrationUserUpdateRolesAndPermissionsCompanyProductRoot],
        parentPath: rp.administrationUserUpdateRolesAndPermissionsCompanyList,
      },

      // Administration / Onboarding
      [rp.administrationOnboardingRoot]: { name: 'Onboarding', parentPath: rp.administrationRoot },

      // Administration / Onboarding / Company
      [`${rp.administrationOnboardingCompanyCreateRoot}/*`]: {
        name: 'Add New Company',
        parentPath: rp.administrationOnboardingRoot,
      },
      [rp.administrationOnboardingCompanyList]: {
        name: 'Manage Companies',
        parentPath: rp.administrationOnboardingRoot,
      },

      // Administration / Onboarding / Company / View company details
      [rp.administrationOnboardingCompanyViewRoot]: {
        name: bcNamesMap[rp.administrationOnboardingCompanyViewRoot],
        parentPath: rp.administrationOnboardingCompanyList,
      },
      [rp.administrationOnboardingCompanyViewDetails]: {
        name: 'Company Details',
        parentPath: rp.administrationOnboardingCompanyViewRoot,
      },
      // Administration / Onboarding / Company / View company details / Schemes
      [rp.administrationOnboardingCompanyViewCreateScheme]: {
        name: 'Add New Scheme',
        parentPath: rp.administrationOnboardingCompanyViewRoot,
      },
      [rp.administrationOnboardingCompanyViewSchemeList]: {
        name: 'Schemes',
        parentPath: rp.administrationOnboardingCompanyViewRoot,
      },
      [rp.administrationOnboardingCompanyViewSchemeRoot]: {
        name: bcNamesMap[rp.administrationOnboardingCompanyViewSchemeRoot],
        parentPath: rp.administrationOnboardingCompanyViewSchemeList,
      },
      [rp.administrationOnboardingCompanyViewSchemeViewSchemeDetails]: {
        name: 'Scheme Details',
        parentPath: rp.administrationOnboardingCompanyViewSchemeRoot,
      },
      [`${rp.administrationOnboardingCompanyViewSchemeViewSections}/*`]: {
        name: 'Benefit Sections',
        parentPath: rp.administrationOnboardingCompanyViewSchemeRoot,
      },
      [rp.administrationOnboardingCompanyViewSchemeViewSchemeSectionsCreateSection]: {
        name: 'Add new section',
        parentPath: rp.administrationOnboardingCompanyViewSchemeRoot,
      },
      [rp.administrationOnboardingCompanyViewSchemeViewSchemeEmployers]: {
        name: 'Employers',
        parentPath: rp.administrationOnboardingCompanyViewSchemeRoot,
      },
      [rp.administrationOnboardingCompanyViewSchemeViewSchemeEmployersViewEmployer]: {
        name: bcNamesMap[rp.administrationOnboardingCompanyViewSchemeViewSchemeEmployersViewEmployer],
        parentPath: rp.administrationOnboardingCompanyViewSchemeViewSchemeEmployers,
      },
      // Administration / Onboarding / Company / View company details / Products
      [rp.administrationOnboardingCompanyViewProducts]: {
        name: 'Products',
        parentPath: rp.administrationOnboardingCompanyViewRoot,
      },
      // Administration / Onboarding / Company / View company details / Scheme Access
      [`${rp.administrationOnboardingCompanyViewSchemeAccessRoot}/*`]: {
        name: 'Scheme Access',
        parentPath: rp.administrationOnboardingCompanyViewRoot,
      },

      // Administration / Onboarding / Company / Update company details
      [rp.administrationOnboardingCompanyUpdateRoot]: {
        name: bcNamesMap[rp.administrationOnboardingCompanyUpdateRoot],
        parentPath: rp.administrationOnboardingCompanyList,
      },
      [rp.administrationOnboardingCompanyUpdateDetails]: {
        name: 'Company Details',
        parentPath: rp.administrationOnboardingCompanyUpdateRoot,
      },
      // Administration / Onboarding / Company / Update company details / Schemes
      [rp.administrationOnboardingCompanyUpdateCreateScheme]: {
        name: 'Add New Scheme',
        parentPath: rp.administrationOnboardingCompanyUpdateRoot,
      },
      [rp.administrationOnboardingCompanyUpdateSchemeList]: {
        name: 'Schemes',
        parentPath: rp.administrationOnboardingCompanyUpdateRoot,
      },
      [rp.administrationOnboardingCompanyUpdateSchemeRoot]: {
        name: bcNamesMap[rp.administrationOnboardingCompanyUpdateSchemeRoot],
        parentPath: rp.administrationOnboardingCompanyUpdateSchemeList,
      },
      [rp.administrationOnboardingCompanyUpdateSchemeUpdateSchemeDetails]: {
        name: 'Scheme Details',
        parentPath: rp.administrationOnboardingCompanyUpdateSchemeRoot,
      },
      [`${rp.administrationOnboardingCompanyUpdateSchemeUpdateSections}/*`]: {
        name: 'Benefit Sections',
        parentPath: rp.administrationOnboardingCompanyUpdateSchemeRoot,
      },
      [rp.administrationOnboardingCompanyUpdateSchemeUpdateSchemeSectionsCreateSection]: {
        name: 'Add new section',
        parentPath: rp.administrationOnboardingCompanyUpdateSchemeRoot,
      },
      [rp.administrationOnboardingCompanyUpdateSchemeUpdateSchemeEmployers]: {
        name: 'Employers',
        parentPath: rp.administrationOnboardingCompanyUpdateSchemeRoot,
      },
      [rp.administrationOnboardingCompanyUpdateSchemeUpdateSchemeEmployersUpdateEmployer]: {
        name: bcNamesMap[rp.administrationOnboardingCompanyUpdateSchemeUpdateSchemeEmployersUpdateEmployer],
        parentPath: rp.administrationOnboardingCompanyUpdateSchemeUpdateSchemeEmployers,
      },
      // Administration / Onboarding / Company / Update company details / Products
      [rp.administrationOnboardingCompanyUpdateProducts]: {
        name: 'Products',
        parentPath: rp.administrationOnboardingCompanyUpdateRoot,
      },
      // Administration / Onboarding / Company / Update company details / Scheme Access
      [`${rp.administrationOnboardingCompanyUpdateSchemeAccessRoot}/*`]: {
        name: 'Scheme Access',
        parentPath: rp.administrationOnboardingCompanyUpdateRoot,
      },

      // Administration / Onboarding / Bulk Onboard
      [rp.administrationOnboardingOnboardRoot]: {
        name: 'Bulk Onboard',
        parentPath: rp.administrationOnboardingRoot,
      },
      [rp.administrationOnboardingBulkUpload]: {
        name: bcNamesMap[rp.administrationOnboardingBulkUpload],
        parentPath: rp.administrationOnboardingOnboardRoot,
      },
      [rp.administrationOnboardingBulkUploadHistory]: {
        name: 'Upload History',
        parentPath: rp.administrationOnboardingOnboardRoot,
      },
      [rp.administrationOnboardingBulkUploadHistoryFile]: {
        name: bcNamesMap[rp.administrationOnboardingBulkUploadHistoryFile],
        parentPath: rp.administrationOnboardingBulkUploadHistory,
      },

      // Administration / Onboarding / Tenant Configuration
      [rp.administrationOnboardingTenantRoot]: {
        name: 'Tenant Configuration',
        parentPath: rp.administrationOnboardingRoot,
      },
      [rp.administrationOnboardingTenantList]: {
        name: 'Manage Tenant',
        parentPath: rp.administrationOnboardingTenantRoot,
      },
      [`${rp.administrationOnboardingTenantCreateRoot}/*`]: {
        name: 'Create New Tenant',
        parentPath: rp.administrationOnboardingTenantList,
      },
      [`${rp.administrationOnboardingTenantCreateRoot}/*`]: {
        name: 'Create New Tenant',
        parentPath: rp.administrationOnboardingTenantList,
      },
      [`${rp.administrationOnboardingTenantUpdateRoot}/*`]: {
        name: bcNamesMap[rp.administrationOnboardingTenantUpdateRoot],
        parentPath: rp.administrationOnboardingTenantList,
      },

      // Administration / API integration
      [rp.apiIntegrationsRoot]: { name: 'API Integrations', parentPath: rp.administrationRoot },
      [`${rp.apiIntegrationsCreateRoot}/*`]: {
        name: 'New API Integration to DataHub',
        parentPath: rp.apiIntegrationsRoot,
      },
      [rp.apiIntegrationsList]: { name: 'Manage Integrations', parentPath: rp.apiIntegrationsRoot },

      // Administration / API integration / View Details
      [rp.apiIntegrationsViewRoot]: {
        name: bcNamesMap[rp.apiIntegrationsViewRoot],
        parentPath: rp.apiIntegrationsList,
      },
      [rp.apiIntegrationsViewDetails]: { name: 'Integration Details', parentPath: rp.apiIntegrationsViewRoot },
      [rp.apiIntegrationsViewCredentials]: { name: 'API credentials', parentPath: rp.apiIntegrationsViewRoot },
      [rp.apiIntegrationsViewProducts]: { name: 'Products', parentPath: rp.apiIntegrationsViewRoot },

      // Administration / API integration / Update Details
      [rp.apiIntegrationsUpdateRoot]: {
        name: bcNamesMap[rp.apiIntegrationsUpdateRoot],
        parentPath: rp.apiIntegrationsList,
      },
      [rp.apiIntegrationsUpdateDetails]: { name: 'Integration Details', parentPath: rp.apiIntegrationsUpdateRoot },
      [rp.apiIntegrationsUpdateCredentials]: { name: 'API credentials', parentPath: rp.apiIntegrationsUpdateRoot },
      [rp.apiIntegrationsUpdateCredentialsIpAddress]: {
        name: bcNamesMap[rp.apiIntegrationsUpdateCredentialsIpAddress],
        parentPath: rp.apiIntegrationsUpdateCredentials,
      },
      [rp.apiIntegrationsUpdateProducts]: { name: 'Products', parentPath: rp.apiIntegrationsUpdateRoot },

      // Administration / API integration / Third Party Integrations
      [rp.apiIntegrationsThirdPartyList]: { name: 'Third Party Integrations ', parentPath: rp.apiIntegrationsRoot },
      [`${rp.apiIntegrationsThirdPartyCreateRoot}/*`]: {
        name: 'New Third Party Integrations',
        parentPath: rp.apiIntegrationsThirdPartyList,
      },
      // Administration / API integration / Third Party Integrations / View Details
      [rp.apiIntegrationsThirdPartyViewRoot]: {
        name: bcNamesMap[rp.apiIntegrationsThirdPartyViewRoot],
        parentPath: rp.apiIntegrationsThirdPartyList,
      },
      [rp.apiIntegrationsThirdPartyViewIntegration]: {
        name: 'Integration Details',
        parentPath: rp.apiIntegrationsThirdPartyViewRoot,
      },
      [rp.apiIntegrationsThirdPartyViewEnvironment]: {
        name: 'Environment Details',
        parentPath: rp.apiIntegrationsThirdPartyViewRoot,
      },
      // Administration / API integration / Third Party Integrations / Edit Details
      [rp.apiIntegrationsThirdPartyEditRoot]: {
        name: bcNamesMap[rp.apiIntegrationsThirdPartyEditRoot],
        parentPath: rp.apiIntegrationsThirdPartyList,
      },
      [rp.apiIntegrationsThirdPartyEditIntegration]: {
        name: 'Integration Details',
        parentPath: rp.apiIntegrationsThirdPartyEditRoot,
      },
      [rp.apiIntegrationsThirdPartyEditEnvironment]: {
        name: 'Environment Details',
        parentPath: rp.apiIntegrationsThirdPartyEditRoot,
      },
    }),
    [bcNamesMap],
  );

  // Debounce breadcrumbs update to avoid unnecessary updates by redirects
  useEffect(() => {
    if (isHidden) return;

    const delayTimeout = setTimeout(() => {
      setBreadcrumbs(getBreadcrumbs(pathname, config));
    }, 50);
    return () => {
      clearTimeout(delayTimeout);
    };
  }, [config, isHidden, pathname]);

  return isHidden ? null : <Breadcrumbs breadcrumbs={breadcrumbs} />;
}

export default BreadcrumbsTrail;
